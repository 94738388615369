<template>
  <!-- 登录页 -->
  <div class="user-login">
    <div class="login-form">
      <div class="tabs">
        <div
          class="tab-item"
          v-for="(item, index) in tabs"
          :key="item.name"
          :class="{ active: currentIndex === index }"
          @click="handleClick(item, index)"
        >
          <span>{{ item.label }}</span>
        </div>
      </div>
      <el-form
        class="form my-height"
        :model="form"
        label-position="left"
        :rules="rules"
        ref="form"
      >
        <el-form-item prop="mobile" v-if="currentIndex === 1">
          <el-input
            v-model="form.mobile"
            placeholder="请输入注册手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="username" v-else>
          <el-input
            v-model="form.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code" v-if="currentIndex === 1">
          <el-input
            style="width: 58%"
            v-model="form.code"
            placeholder="请输入短信验证码"
            @keyup.enter.native="loginByCode"
          ></el-input>
          <el-button
            class="btn"
            type="primary"
            @click="getSmsCode"
            :disabled="flag"
            >{{ flag ? `（${current}） s` : "获取验证码" }}</el-button
          >
        </el-form-item>
        <el-form-item prop="password" v-else>
          <el-input
            type="password"
            v-model="form.password"
            placeholder="请输入密码"
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <Verify
            @success="verifySuccess"
            :mode="'pop'"
            :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }"
            ref="verify"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            style="width: 100%"
            type="primary"
            @click="login"
            v-if="currentIndex === 0"
            >登录</el-button
          >
          <el-button
            style="width: 100%"
            type="primary"
            @click="loginByCode"
            v-else
            >登录</el-button
          >
        </el-form-item>
      </el-form>
      <div class="register">
        <span @click="register">立即注册</span> |
        <span @click="resetPwd">忘记密码？</span>
      </div>
    </div>
  </div>
</template>

<script>
import Verify from "../../components/verifition/Verify";
import { getSmsCodeNew } from "@/api/certificate";
import { getUserMenuByIdentity } from "@/api/add";
import { telephoneValidator } from "../../js/utils/rules";
import { getStore } from "../../js/utils/store";
import { mapGetters, mapMutations } from "vuex";
import { UnreadMessage } from "@/api/websocket";
export default {
  components: {
    Verify,
  },
  data() {
    return {
      tabs: [
        { label: "用户登录", name: "0" },
        { label: "验证码登录", name: "1" },
      ],
      form: { randomStr: "blockPuzzle" },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { trigger: "blur", validator: telephoneValidator },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
      currentIndex: 0,
      flag: false,
      max: 60,
      current: 60,
      decreseInterval: null,
    };
  },
  computed: mapGetters(["userInfo",  "identityObj",]),
  created() {
    // this.UnMessage()
  },
  methods: {
    ...mapMutations(["setVipTree","SET_MESSAGE"]),
    async UnMessage() {
      let params = {
        receiverId: this.identityObj.identityId,
      };
      let res = await UnreadMessage(params);
      let AllMessages = res.data;
      this.SET_MESSAGE(AllMessages);
    },


    handleClick(item, index) {
      // 选择登录类型
      this.currentIndex = index;
      this.form = { randomStr: "blockPuzzle" };
      this.$refs.form.resetFields();
    },
    async getSmsCode() {
      // 获取短信验证码
      if (this.form.mobile) {
        let param = {
          phone: this.form.mobile,
          type: "2",
        };
        let res = await getSmsCodeNew(param);
        console.log(res);
        let { code, data, msg } = res;
        if (code == 0) {
          this.$message.success(msg);
          this.current = this.max;
          this.decreseInterval = setInterval(() => {
            if (this.current <= this.max && this.current > 0) {
              this.current--;
              this.flag = true;
            } else if (this.current <= 0) {
              this.current = 0;
              this.flag = false;
              clearInterval(this.decreseInterval);
            }
          }, 1000);
        } else {
          this.$message.error(msg);
        }
      } else {
        this.$message.error("请先输入手机号！");
      }
    },
    login() {
      // 用户名登录
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.verify.show();
        }
      });

    },
    transMenu(tree, parentName = "") {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        const fullName = parentName + "/" + node.path; // 拼接全称
        if (node.children) {
          this.transMenu(node.children, fullName); // 递归调用函数遍历子节点
        }
        node.allPath = fullName; // 赋值全称给节点
      }
      return tree;
    },
    async getOthersInfo() {
      try {
        let res = await getUserMenuByIdentity();
        if (res.code == 0 && res.data.menuList.length > 0) {
          res.data.menuList.forEach((item) => {
            if (item.path === "memberCenter") {
              let arr = this.transMenu([item][0].children, "/memberCenter");
              if (arr.length > 0) {
                this.setVipTree(arr);
                this.$router.$avueRouter.formatRoutes(arr, true);
                if (arr[0].children.length == 0) {
                  this.$router.push({
                    path: arr[0].allPath,
                  });
                } else {
                  if (arr[0].children.length > 0) {
                    this.$router.push({
                      path: arr[0].children[0].allPath,
                    });
                  }
                }
              }
            }
          });
        }
      } catch (error) {}
    },
    verifySuccess(params) {
      this.form.code = params.captchaVerification;
      let info = getStore({ name: "userInfo" });
      this.$store
        .dispatch("LoginByUsername", this.form)
        .then((res) => {
          if (info == "timeout") {
            this.$message.error("该帐号已超过授权时间，请联系管理员！");
          } else {
            if (this.userInfo.identityList.length == 0) {
              if (this.userInfo.auditIdentity == 1) {
                this.$router.push({ name: "register1" });
              } else if (this.userInfo.auditIdentity == 2) {
                this.$router.push({ name: "register2" });
              } else if (this.userInfo.auditIdentity == 3){
                this.$router.push({ name: "register3" });
              }
            } else if (this.userInfo.identityList.length == 1) {
              this.$store.dispatch(
                "SetIdentityObj",
                this.userInfo.identityList[0]
              );
              this.getOthersInfo();
            } else {
              this.$router.push({ name: "chooseRole" });
            }
          }
          console.log(11111111111111111111111);
          this.UnMessage()
          
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    loginByCode() {

      // 手机验证码登录
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("LoginByPhone", this.form).then((res) => {
            if (this.userInfo.identityList.length == 0) {
              if (this.userInfo.auditIdentity == 1) {
                this.$router.push({ name: "register1" });
              } else if (this.userInfo.auditIdentity == 2) {
                this.$router.push({ name: "register2" });
              } else if (this.userInfo.auditIdentity == 3){
                this.$router.push({ name: "register3" });
              }
            } else if (this.userInfo.identityList.length == 1) {
              this.$store.dispatch(
                "SetIdentityObj",
                this.userInfo.identityList[0]
              );
              this.getOthersInfo();
            } else {
              this.$router.push({ name: "chooseRole" });
            }
          }).catch(err=>{

          });
         
        }
        // this.UnMessage()
      });
    },
    register() {
      // 立即注册
      this.$router.push({ name: "register" });
    },
    resetPwd() {
      // 修改密码
      this.$router.push({ name: "resetPwd" });
    },
  },
};
</script>

<style lang="less" scoped>
.user-login {
  height: 651px;
  width: 100%;
  position: relative;
  z-index: 3;
  background-image: url("../../assets/imgs/login-bg.png");
  background-position: 0 45%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-top: 92px;
  .login-form {
    // width: 400px;
    width: 350px;
    // height: 434px;
    height: 370px;
    // padding: 40px 53px 0 53px;
    padding: 40px 25px 0 25px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0px auto;
    .tabs {
      width: calc(100% - 100px);
      padding: 0 50px;
      height: 42px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tab-item {
        width: 215px;
        height: 40px;
        line-height: 20px;
        margin-right: 35px;
        font-size: 18px;
      }
      .active {
        color: #00a0e9;
        border-bottom: 2px solid #00a0e9;
      }
    }
    .form {
      margin-top: 32px;
      .el-form-item {
        margin-bottom: 23px;
      }
      .btn {
        width: 132px;
        height: 46px;
        margin-left: 15px;
      }
    }
    .register {
      color: #00a0e9;
      text-align: right;
      span {
        cursor: pointer;
      }
    }
    .form2 {
      .el-form-item {
        margin-bottom: 15px;
      }
    }
  }
}
</style>

<style lang="less">
.my-height {
  .el-input__inner {
    height: 48px;
  }
}
</style>
